import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPropsStore, Props } from '@wix/thunderbolt-symbols'
import { QuickActionBarItemProps } from '@wix/thunderbolt-components'
import type { ISamePageAnchorPropsResolver } from './types'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'

export const SamePageAnchorPropsResolver = withDependencies(
	[Props, UrlHistoryManagerSymbol],
	(propsStore: IPropsStore, urlHistoryManager: IUrlHistoryManager): ISamePageAnchorPropsResolver => {
		const getQABUpdatedProps = (compId: string, fullUrl: string) => {
			const currentActionBarItems = propsStore.get('QUICK_ACTION_BAR').items
			const updatedItems = currentActionBarItems.map((item: QuickActionBarItemProps) =>
				item.compId === compId
					? {
							...item,
							link: { ...item.link, href: fullUrl },
					  }
					: item
			)

			return {
				items: updatedItems,
			}
		}

		return {
			getPropsOverrides: ({ compId, compType }) => {
				const fullUrl = urlHistoryManager.getFullUrlWithoutQueryParams()
				const isQABItem = compType === 'QuickActionBarItem'

				const targetCompIdForPropsUpdate = isQABItem ? 'QUICK_ACTION_BAR' : compId
				const updatedCompProps = isQABItem
					? getQABUpdatedProps(compId, fullUrl)
					: {
							link: {
								...propsStore.get(targetCompIdForPropsUpdate).link,
								href: fullUrl,
							},
					  }

				return {
					[targetCompIdForPropsUpdate]: updatedCompProps,
				}
			},
		}
	}
)
